import { gql } from "@apollo/client";

export const GET_SIMILAR_POSTS = gql`
  query GetSimilarPostsV2($postId: Int!, $type: Int) {
    result: SimilarPostsV2(postId: $postId, type: $type) {
      ok
      error
      items {
        id
        imageUrl
        postImageId
        distance
        CTR
        isSaleable
        saleableProductCnt
        instagramId
        userId
      }
      type
    }
  }
`;

export const GET_SIMILAR_POSTS_V1 = gql`
  query GetSimilarPostsV1($postId: Int!, $type: Int) {
    result: SimilarPosts(postId: $postId, type: $type) {
      ok
      error
      items {
        id
        imageUrl
        isSaleable
        saleableProductCnt
        user {
          id
          instagramId
          gender
        }
        userId
      }
      type
    }
  }
`;

export const GET_BLOG_POSTS = gql`
  query GetBlogPosts($page: Int, $perPage: Int) {
    result: Posts(
      type: "BLOG"
      page: $page
      perPage: $perPage
      filter: { status: "ACTIVE", cache: false }
    ) {
      ok
      error
      items {
        id
        imageUrl
        type
        blogDetail
        createdAt
        tag
        user {
          id
          gender
          instagramId
          allowSearchEngineIndex
        }
      }
    }
  }
`;

export const POST_DETAIL_QUERY = gql`
  query GetPost($id: String, $cache: Boolean) {
    post: Post(id: $id, cache: $cache) {
      ok
      error
      items {
        id
        type
        userId
        imageUrl
        videoUrl
        youtubeVideoId
        youtubeVideoTitle
        youtubeVideoTime
        detail
        status
        createdBy
        isDelete
        blogDetail
        tag
        quickLinkName
        quickLinkUrl
        user {
          id
          gender
          instagramId
          imageUrl
          height
          creator {
            id
            userId
            height
            weight
            bodyShape
            preferStyle
            preferBrand
            isHideWeight
            job
            youtubeChannelName
            youtubeChannelHandle
            youtubeChannelProfileImageUrl
          }
          allowSearchEngineIndex
        }
        images {
          id
          postId
          url
        }
        postItems {
          id
          isWear
          category
          time
          size
          postId
          product {
            id
            imageUrl
            brandId
            brand {
              id
              name
              nameKr
              isMs
            }
            name
            nameEn
            category
            subCategory
            price
            isSale
            salePrice
            salePercentage
            saleLink
            displayName
            seasonType
            needVerify
            isRegisteredCafe24
            cafe24ProductId
            productItems {
              id
              size
              stock
              status
            }
            status
          }
        }
        styleTags {
          id
          name
          code
          displayName
          displayNameEn
          displayNameJa
        }
        isSaleable
        saleableProductCnt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;

export const GET_LIKE_INFO = gql`
  query GetLikeInfo($postId: Int!) {
    likes: PostLikes(postId: $postId) {
      ok
      error
      items {
        userId
        postId
        x
        y
      }
      count
    }
    isLiked: IsLikedPost(postId: $postId) {
      ok
      error
    }
  }
`;

export const DO_LIKE_POST = gql`
  mutation DoLikePost($postId: Int!, $imgIndex: Int, $x: String, $y: String) {
    result: DoLikePost(postId: $postId, imgIndex: $imgIndex, x: $x, y: $y) {
      ok
      error
      result {
        id
        is
        count
      }
    }
  }
`;

export const GET_POST_ENGAGE = gql`
  query GetPostEngage($postId: Int!, $targetUserId: Int!) {
    subs: IsSubscription(targetUserId: $targetUserId) {
      ok
      error
      isAlarm
    }

    like: IsLikedPost(postId: $postId) {
      ok
      error
    }
  }
`;

export const GET_POST_ENGAGEMENT = gql`
  query GetPostEngagement($id: String!) {
    result: PostEngagement(id: $id) {
      ok
      error
      items {
        like {
          id
          is
          count
        }
        subs {
          id
          isSubs
          isAlarm
          count
        }
        bookmark {
          count
          postBookmark {
            id
            is
            count
          }
          productBookmark {
            is
            productId
            count
          }
        }
        postBookmark {
          id
          is
          count
        }
        productBookmark {
          id
          is
          count
        }
      }
    }
  }
`;

export const DO_ALARM_CREATOR = gql`
  mutation DoAlarmCeartor($targetUserId: Int!) {
    result: DoAlarmCreator(targetUserId: $targetUserId) {
      ok
      error
      result {
        id
        isSubs
        isAlarm
        count
      }
    }
  }
`;

export const CANCEL_ALARM_CREATOR = gql`
  mutation CancelAlarmCeartor($targetUserId: Int!) {
    result: CancelAlarmCreator(targetUserId: $targetUserId) {
      ok
      error
      result {
        id
        isSubs
        isAlarm
        count
      }
    }
  }
`;

export const GET_POST_ITEM_POINTS = gql`
  query PostItemPoints($postId: Int!) {
    result: PostItemPoints(postId: $postId) {
      ok
      error
      total
      items {
        postId
        category
        x
        y
        arrowDirection
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GetSimpleProducts($ids: [Int]) {
    result: Products(filter: { ids: $ids }) {
      ok
      error
      total
      items {
        id
        name
        brand {
          id
          name
          nameKr
          isMs
        }
        imageUrl
        category
        subCategory
        price
        salePrice
        saleLink
        isSale
      }
    }
  }
`;

export const CANCEL_LIKE_POST = gql`
  mutation CancelLikePost($postId: Int!) {
    result: CancelLikePost(postId: $postId) {
      ok
      error
      result {
        id
        is
        count
      }
    }
  }
`;

export const DO_DELETE_POST = gql`
  mutation DoDeletePost($id: Int!) {
    DeletePost(id: $id) {
      id
      ok
      error
    }
  }
`;

export const GET_BRAND = gql`
  query Brand($id: String!) {
    Brand(id: $id) {
      ok
      error
      items {
        name
        imageUrl
      }
    }
  }
`;
